import { useDispatch, useSelector } from "react-redux";
import Popup from "../molecules/Popup";
import Button from "../atoms/Button";
import DashboardTable from "../organismes/DashboardTable";
import CategoryItems from "../molecules/dashboard/categoryItems";
import Input from "../atoms/Input";
import UxerCard from "../organismes/uxerCard";
import { ReactComponent as Hum2 } from "../../assets/icons/hum2.svg";
import "../../styles/dashboard.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Caution } from "../../assets/icons/caution.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/clear.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as Ok } from "../../assets/icons/ok.svg";
import { ReactComponent as OrderAZ } from "../../assets/icons/order_az.svg";
import { ReactComponent as OrderZA } from "../../assets/icons/order_za.svg";
import { ReactComponent as ViewLinkIcon } from "../../assets/icons/view_link.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as Services } from "../../assets/icons/services.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as AddProjectIcon } from "../../assets/icons/add_project.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add_1.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Image } from "../../assets/icons/image.svg";
import { ReactComponent as Fav } from "../../assets/icons/fav_line.svg";
import { ReactComponent as Cal } from "../../assets/icons/cal.svg";
import { ReactComponent as Click } from "../../assets/icons/hand4.svg";
import { ReactComponent as Link } from "../../assets/icons/view_link.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import InfoPopupContainer from "../organismes/popup/InfoPopupContainer";
import { fetchImage } from "../../services/itemsServices";
import InfoPopup from "../organismes/popup/InfoPopup";
import {
  addItem,
  addManyItems,
  deleteItem,
  editItem,
  getAllItems,
  uploadManyImage,
} from "../../services/AdminService";
import { useEffect, useMemo, useRef, useState } from "react";
import ItemImage from "../molecules/ItemImage";
import DropDown from "../atoms/DropDown";
import AddItemDashboardPopup from "../molecules/AddItemDashboardPopup";
import DashboardLayout from "../layout/DashboardLayout";
import PopupContainer from "../molecules/PopupContainer";
import Loading from "../atoms/Loading";

function DashboardItems({}) {
  const user = useSelector((state) => state.user);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showLeftBar, setShowLeftBar] = useState(false);
  const [sortConfig, setSortConfig] = useState(null);
  const [data, setData] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filterCategory, setFilterCategory] = useState("all");
  const [openAddData, setOpenAddData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const inputFileRef = useRef();
  const lang = useSelector((state) => state.lang);
  const inputFileImagesRef = useRef();
  const parentPopupRef = useRef();
  const [infoPopup, setInfoPopup] = useState([]);
  const [popMessage, setPopMessage] = useState("");
  const [popIcon, setPopIcon] = useState("");
  const [popType, setPopType] = useState("");
  const [IsShowPopup, settIsShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteItem = (item_id) => {
    deleteItem(item_id).then((val) => {
      if (val.data) {
        getData();
        setConfirmDelete(false);
        popups("item deleted successfully", "success", <Ok />);
      }
    });
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const csv = data.map((row) => Object.values(row).join(",")).join("\n");

    return `${header}\n${csv}`;
  };

  const downloadCSV = () => {
    const data = sortedItems.map((ele) => {
      return {
        img: ele.img,
        name: ele.name,
        link: ele.link,
        engDescription:
          ele.description.length > 0
            ? ele.description[0].replaceAll(",", ";")
            : "",
        jpDescription:
          ele.description.length > 1
            ? ele.description[1].replaceAll(",", ";")
            : "",
        filterTag:
          ele.filterTag !== null
            ? ele.filterTag.join(",").replaceAll(",", ";")
            : "",
        tags: ele.tags !== null ? ele.tags.join(",").replaceAll(",", ";") : "",
        platform:
          ele.platform !== null
            ? ele.platform.join(",").replaceAll(",", ";")
            : "",
        price: ele.price,
        category_id: ele.category_id.length > 0 ? ele.category_id[0]._id : "",
        fav: ele.likes,
        clicks: ele.clicks,
      };
    });
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  function popups(message, type, icon) {
    settIsShowPopup(true);
    setPopType(type);
    setPopIcon(icon);
    setPopMessage(message);
    setTimeout(() => {
      settIsShowPopup(false);
    }, 4000);
  }

  const handleAddItem = async (data, image) => {
    try {
      await addItem(data, image);
      getData();
      setOpenAddData("");
      popups("item added successfully", "success", <Ok />);
    } catch (err) {
      popups(err.response.data, "error", <Caution />);
    }
  };

  const handleAddImages = async (event) => {
    const files = Array.from(event.target.files);

    const allowedTypes = ["image/svg+xml", "image/svg", "image/png"];

    const filesToUpload = files.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (filesToUpload.length === 0) return;

    event.target.value = null;
    setLoading(true);

    try {
      await uploadManyImage(filesToUpload);
      setLoading(false);
      popups("Images added successfully", "success", <Ok />);
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
      popups("Error adding images", "error", <Caution />);
    }
  };

  const handleUpdateItem = (data, image) => {
    editItem(data, image).then((val) => {
      if (val.messageError) {
        popups("Item not update", "error", <Caution />);

        return;
      }

      popups("Item update successfully", "success", <Ok />);

      getData();
    });
  };

  const sortedItems = useMemo(() => {
    let sortableItems = Array.isArray(data) ? [...data] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (
          sortConfig.key === "category_id" &&
          sortConfig.key in a &&
          sortConfig.key in b
        ) {
          if (
            a[sortConfig.key].length > 0 &&
            b[sortConfig.key].length > 0 &&
            a[sortConfig.key][0]._id < b[sortConfig.key][0]._id
          ) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (
            a[sortConfig.key].length > 0 &&
            b[sortConfig.key].length > 0 &&
            a[sortConfig.key][0]._id > b[sortConfig.key][0]._id
          ) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleChangeResearch = (e) => {
    const value = e.target.value;

    setFilterText(value);
  };

  const downloadErrorFile = (data) => {
    const blob = new Blob([data], { type: "text/plain" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "items_upload_errors.txt");
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleAddFile = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (fileObj.type !== "text/csv") {
      return;
    }

    console.log("fileObj is", fileObj);

    event.target.value = null;

    addManyItems(fileObj)
      .then((res) => {
        popups(res.data, "success", <Ok />);
      })
      .catch((error) => {
        popups(
          error.response?.data || error.response?.data?.message,
          "error",
          <Caution />
        );
        if (error.response?.data.split("->").length > 1) {
          downloadErrorFile(error.response?.data);
        }
      });
  };

  const getData = () => {
    setLoading(true);
    getAllItems()
      .then((val) => {
        if (val.data.items) {
          setData(val.data.items);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDataForInterval = () => {
    getAllItems()
      .then((val) => {
        if (val.data.items) {
          setData(val.data.items);
        }
      })
      .catch(() => {});
  };

  const addNewItem = () => {
    setOpenAddData(
      <AddItemDashboardPopup
        onClose={() => setOpenAddData(false)}
        card={{}}
        onSave={(data, image) => handleAddItem(data, image)}
      />
    );
  };

  const modifyItem = (data) => {
    setOpenAddData(
      <AddItemDashboardPopup
        onClose={() => setOpenAddData(false)}
        card={{
          ...data,
          engDescription:
            typeof data.description === "object"
              ? data.description[0]
              : data.description,
          jpDescription:
            typeof data.description === "object" ? data.description[1] : "",
        }}
        onSave={(data, image) => handleUpdateItem(data, image)}
      />
    );
  };

  useEffect(() => {
    getData();
    const interval = setInterval(getDataForInterval, 30000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="dashboard-filter-items" ref={parentPopupRef}>
            <div className="dashboard-button-group">
              <Button
                rightIcon={<Download className="icon" />}
                className="primary-green"
                onClick={downloadCSV}
              >
                CSV
              </Button>
              <Button
                rightIcon={<AddProjectIcon className="icon" />}
                className="primary"
                onClick={() => inputFileRef.current.click()}
              >
                Add CSV
              </Button>

              <Button
                rightIcon={<AddIcon className="icon" />}
                className="secondary"
                onClick={addNewItem}
              >
                Add
              </Button>
              <Button
                rightIcon={<Image className="icon" />}
                className="secondary"
                onClick={() => inputFileImagesRef.current.click()}
              >
                Img
              </Button>
            </div>

            <div className="dashboard-button-group">
              {" "}
              <DropDown
                leftIcon={null}
                rightIcon={<Hum2 className="dashboard-select-right-icon" />}
                placeHolder={
                  <div className="dashboard-select-placeholder-cont">
                    ALL
                    <span className="dashbaord-select-indicator">
                      {data?.length}
                    </span>
                  </div>
                }
                defaultValue={filterCategory}
                onChange={(value) => setFilterCategory(value)}
                options={[
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>ALL</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {data?.length}
                        </span>
                      </div>
                    ),
                    value: "all",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>TOOLS</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter((item) =>
                              item?.category_id?.length > 0
                                ? item?.category_id[0].name === "tools"
                                : false
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "tools",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>RESOS</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter((item) =>
                              item?.category_id?.length > 0
                                ? item?.category_id[0].name === "resos"
                                : false
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "resos",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>IDEAZ</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter((item) =>
                              item?.category_id?.length > 0
                                ? item?.category_id[0].name === "ideaz"
                                : false
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "ideaz",
                  },
                  {
                    label: (
                      <div className="dashboard-select-option-cont">
                        <span>APPS</span>{" "}
                        <span className="dashbaord-select-indicator">
                          {
                            data?.filter((item) =>
                              item?.category_id?.length > 0
                                ? item?.category_id[0].name === "apps"
                                : false
                            ).length
                          }
                        </span>
                      </div>
                    ),
                    value: "apps",
                  },
                ]}
              />
              <Input
                leftIcon={<SearchIcon />}
                rightIcon={
                  filterText ? (
                    <div className="flex">
                      {" "}
                      <span className="dashboard-search-number">
                        {
                          sortedItems
                            .filter((item) => {
                              const descriptionMatches = item?.description.find(
                                (desc) =>
                                  desc
                                    .toLowerCase()
                                    .includes(filterText.toLowerCase())
                              );
                              return (
                                item?.name
                                  ?.toLowerCase()
                                  .includes(filterText.toLowerCase()) ||
                                item?.tags
                                  .map((ele) => ele.toLowerCase())
                                  .includes(filterText?.toLowerCase()) ||
                                item?.filterTag
                                  .map((ele) => ele.toLowerCase())
                                  .includes(filterText?.toLowerCase()) ||
                                item?.link?.includes(
                                  filterText?.toLowerCase()
                                ) ||
                                item?.img?.includes(
                                  filterText?.toLowerCase()
                                ) ||
                                descriptionMatches !== undefined
                              );
                            })
                            .filter((item) =>
                              filterCategory === "all"
                                ? true
                                : item?.category_id?.length > 0
                                ? item?.category_id[0].name === filterCategory
                                : false
                            ).length
                        }
                      </span>
                      <ClearIcon
                        className="dashboard-clear-icon"
                        onClick={() => {
                          setFilterText("");
                        }}
                      />
                    </div>
                  ) : null
                }
                className="dashboard-search"
                IconClickEvent={(e) => {}}
                rotateIcon={true}
                placeHolder={"Search"}
                value={filterText}
                primaryColor={"Orange"}
                onChange={handleChangeResearch}
              />
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              name="csvFile"
              ref={inputFileRef}
              onChange={handleAddFile}
            />
            <input
              style={{ display: "none" }}
              type="file"
              name="imagesFiles"
              multiple={true}
              ref={inputFileImagesRef}
              onChange={handleAddImages}
            />
          </div>
          <DashboardTable
            className="dashboard-table"
            theadClassName="items-thead"
            header={[
              {
                label: <Services className="dashboard-table-header-icon" />,
                align: "left",
                rightIcon:
                  sortConfig?.key === "category_id" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("category_id"),
              },
              {
                label: <Image className="dashboard-table-header-icon" />,
                align: "center",
                rightIcon: null,
                sort: null,
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Services className="dashboard-table-header-icon" />{" "}
                    <p>Items Name</p>
                  </div>
                ),
                align: "left",
                rightIcon:
                  sortConfig?.key === "name" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("name"),
              },

              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Fav className="dashboard-table-header-icon" /> <p>Favs</p>
                  </div>
                ),
                align: "right",
                rightIcon:
                  sortConfig?.key === "likes" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("likes"),
              },
              {
                label: (
                  <div className="dashboard-table-header-items">
                    <Click className="dashboard-table-header-icon" />{" "}
                    <p>Clicks</p>
                  </div>
                ),
                align: "right",
                rightIcon:
                  sortConfig?.key === "clicks" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("clicks"),
              },
              {
                label: <Link className="dashboard-table-header-icon" />,
                align: "center",
                rightIcon:
                  sortConfig?.key === "link" ? (
                    sortConfig.direction === "descending" ? (
                      <OrderZA />
                    ) : (
                      <OrderAZ />
                    )
                  ) : (
                    <OrderAZ />
                  ),
                sort: () => requestSort("link"),
              },
              {
                label: <Setting className="dashboard-table-header-icon" />,
                align: "center",

                sort: () => requestSort("updatedAt"),
              },
            ]}
            values={
              sortedItems?.length > 0
                ? sortedItems
                    .filter((item) => {
                      const descriptionMatches = item?.description.find(
                        (desc) =>
                          desc
                            ?.toLowerCase()
                            .includes(filterText?.toLowerCase())
                      );
                      return (
                        item?.name
                          ?.toLowerCase()
                          .includes(filterText?.toLowerCase()) ||
                        item?.tags
                          .map((ele) => ele.toLowerCase())
                          .includes(filterText?.toLowerCase()) ||
                        item?.filterTag
                          .map((ele) => ele.toLowerCase())
                          .includes(filterText?.toLowerCase()) ||
                        item?.link?.includes(filterText?.toLowerCase()) ||
                        item?.img?.includes(filterText?.toLowerCase()) ||
                        descriptionMatches !== undefined
                      );
                    })
                    .filter((item) =>
                      filterCategory === "all"
                        ? true
                        : item?.category_id?.length > 0
                        ? item?.category_id[0].name === filterCategory
                        : false
                    )
                    .map((item) => {
                      // category={item?.category_id?.length > 0 ? item.category_id[0].name : "")}
                      return [
                        <CategoryItems
                          category={
                            item?.category_id?.length > 0
                              ? item.category_id[0].name.toUpperCase()
                              : ""
                          }
                        />,
                        <div className="item-image-table">
                          <ItemImage id_item={item?._id} className="item-img" />
                        </div>,
                        item?.name,
                        item?.likes,
                        item?.clicks,
                        <a
                          href={
                            item?.link?.startsWith("http")
                              ? item?.link
                              : `http://${item?.link}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ViewLinkIcon className="viewLink" />
                        </a>,
                        <div className="action-icons">
                          <EditIcon
                            onClick={() =>
                              modifyItem({
                                ...item,
                                category:
                                  item?.category_id?.length > 0
                                    ? item.category_id[0].name
                                    : "",
                              })
                            }
                          />
                          <TrashIcon
                            onClick={() => setConfirmDelete(item?._id)}
                          />
                        </div>,
                      ];
                    })
                : []
            }
          />
        </>
      )}

      {openAddData && (
        <PopupContainer
          windowClass="dashboard-edit-popup"
          className="dashboard-edit-popup-cont"
          cardClass="add_item_box"
          innerClass="inner_box"
        >
          {openAddData}
        </PopupContainer>
      )}
      {confirmDelete && (
        <PopupContainer title={"Confiramtion!"} logo={<Logo />}>
          <div className="w-full">
            <p className="dashboard-delete-info-1">
              Do you really want to remove this user definitely?
            </p>
            <div className="user-form-body-buttons">
              <Button
                rightIcon={<CloseIcon />}
                onClick={() => {
                  setConfirmDelete(false);
                }}
                className="dashboard-delete-button-1 "
                data-lang={lang}
              >
                {lang === "en" ? "Cancel" : "キャンセル"}
              </Button>

              <Button
                rightIcon={<LoginIcon />}
                className="dashboard-delete-button-2 "
                onClick={() => handleDeleteItem(confirmDelete)}
                data-lang={lang}
              >
                {lang === "en" ? "Delete " : "サインアップ"}
              </Button>
            </div>
          </div>
        </PopupContainer>
      )}
      {parentPopupRef.current && (
        <InfoPopupContainer
          positionX={parentPopupRef.current.getBoundingClientRect().x}
          width={parentPopupRef.current.getBoundingClientRect().width}
        >
          {infoPopup.map((infoPopupItem) => (
            <InfoPopup
              icon={<Caution />}
              title={infoPopupItem.title}
              key={infoPopupItem.id}
              color={infoPopupItem.color}
              bgColor={infoPopupItem.bgColor}
              onClose={() =>
                setInfoPopup(infoPopup.filter((x) => x.id !== infoPopupItem.id))
              }
            />
          ))}
        </InfoPopupContainer>
      )}
      {IsShowPopup ? (
        <Popup
          message={popMessage}
          type={popType}
          icon={popIcon}
          closePopup={() => settIsShowPopup(false)}
        />
      ) : null}
    </>
  );
}

export default DashboardItems;
